/* Roboto Fonts */

@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('./Roboto-Regular.ttf');
  src: url('./Roboto-Regular.woff2') format('woff2'),
    url('./Roboto-Regular.woff') format('woff'),
    url('./Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('./Roboto-Italic.ttf');
  src: url('./Roboto-Italic.woff2') format('woff2'),
    url('./Roboto-Italic.woff') format('woff'),
    url('./Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('./Roboto-Bold.ttf');
  src: url('./Roboto-Bold.woff2') format('woff2'),
    url('./Roboto-Bold.woff') format('woff'),
    url('./Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src: url('./Roboto-BoldItalic.ttf');
  src: url('./Roboto-BoldItalic.woff2') format('woff2'),
    url('./Roboto-BoldItalic.woff') format('woff'),
    url('./Roboto-BoldItalic.ttf') format('truetype');
}

/* Arial Substitute - Arimo */

@font-face {
  font-family: 'arial';
  src: url('./Arimo-MediumItalic.ttf');

  src: url('./Arimo-MediumItalic.woff2') format('woff2'),
      url('./Arimo-MediumItalic.woff') format('woff'),
      url('./Arimo-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
font-family: 'arial';
src: url('./Arimo-Medium.ttf');
  src: url('./Arimo-Medium.woff2') format('woff2'),
    url('./Arimo-Medium.woff') format('woff'),
    url('./Arimo-Medium.ttf') format('truetype');
font-weight: 500;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'arial';
src: url('./Arimo-BoldItalic.ttf');
  src: url('./Arimo-BoldItalic.woff2') format('woff2'),
    url('./Arimo-BoldItalic.woff') format('woff'),
    url('./Arimo-BoldItalic.ttf') format('truetype');
font-weight: bold;
font-style: italic;
font-display: swap;
}

@font-face {
font-family: 'arial';
src: url('./Arimo-Bold.ttf');
  src: url('./Arimo-Bold.woff2') format('woff2'),
    url('./Arimo-Bold.woff') format('woff'),
    url('./Arimo-Bold.ttf') format('truetype');
font-weight: bold;
font-style: normal;
font-display: swap;
}

/* Bookman substitute - LibreBaskerville */

@font-face {
font-family: 'bookman';
src: url('./LibreBaskerville-Regular.ttf');
  src: url('./LibreBaskerville-Regular.woff2') format('woff2'),
    url('./LibreBaskerville-Regular.woff') format('woff'),
    url('./LibreBaskerville-Regular.ttf') format('truetype');
font-weight: normal;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'bookman';
src: url('./LibreBaskerville-Bold.ttf');
  src: url('./LibreBaskerville-Bold.woff2') format('woff2'),
    url('./LibreBaskerville-Bold.woff') format('woff'),
    url('./LibreBaskerville-Bold.ttf') format('truetype');
font-weight: bold;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'bookman';
src: url('./LibreBaskerville-Italic.ttf');
  src: url('./LibreBaskerville-Italic.woff2') format('woff2'),
    url('./LibreBaskerville-Italic.woff') format('woff'),
    url('./LibreBaskerville-Italic.ttf') format('truetype');
font-weight: normal;
font-style: italic;
font-display: swap;
}

/* Cursive substitute - Kalam */

@font-face {
font-family: 'cursive';
src: url('./Kalam-Regular.ttf');
  src: url('./Kalam-Regular.woff2') format('woff2'),
    url('./Kalam-Regular.woff') format('woff'),
    url('./Kalam-Regular.ttf') format('truetype');
font-weight: normal;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'cursive';
src: url('./Kalam-Bold.ttf');
  src: url('./Kalam-Bold.woff2') format('woff2'),
    url('./Kalam-Bold.woff') format('woff'),
    url('./Kalam-Bold.ttf') format('truetype');
font-weight: bold;
font-style: normal;
font-display: swap;
}

/* Courier substitute - Courier Prime */

@font-face {
font-family: 'courier';
font-weight: normal;
font-style: normal;
font-display: swap;
src: url('./CourierPrime-Regular.ttf');
src: url('./CourierPrime-Regular.woff2') format('woff2'),
  url('./CourierPrime-Regular.woff') format('woff'),
  url('./CourierPrime-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'courier';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('./CourierPrime-Italic.ttf');
  src: url('./CourierPrime-Italic.woff2') format('woff2'),
    url('./CourierPrime-Italic.woff') format('woff'),
    url('./CourierPrime-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'courier';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('./CourierPrime-Bold.ttf');
  src: url('./CourierPrime-Bold.woff2') format('woff2'),
    url('./CourierPrime-Bold.woff') format('woff'),
    url('./CourierPrime-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'courier';
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src: url('./CourierPrime-BoldItalic.ttf');
  src: url('./CourierPrime-BoldItalic.woff2') format('woff2'),
    url('./CourierPrime-BoldItalic.woff') format('woff'),
    url('./CourierPrime-BoldItalic.ttf') format('truetype');
}

/* Fantasy substitute - Oswald */

@font-face {
font-family: 'fantasy';
src: url('./Oswald-SemiBold.ttf');
  src: url('./Oswald-SemiBold.woff2') format('woff2'),
    url('./Oswald-SemiBold.woff') format('woff'),
    url('./Oswald-SemiBold.ttf') format('truetype');
font-weight: normal;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'fantasy';
src: url('./Oswald-Bold.ttf');
  src: url('./Oswald-Bold.woff2') format('woff2'),
    url('./Oswald-Bold.woff') format('woff'),
    url('./Oswald-Bold.ttf') format('truetype');
font-weight: bold;
font-style: normal;
font-display: swap;
}

/* Helvetica substitute - Lato */

@font-face {
font-family: 'helvetica';
font-weight: normal;
font-style: normal;
font-display: swap;
src: url('./Lato-Regular.ttf');
src: url('./Lato-Regular.woff2') format('woff2'),
  url('./Lato-Regular.woff') format('woff'),
  url('./Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'helvetica';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('./Lato-Italic.ttf');
  src: url('./Lato-Italic.woff2') format('woff2'),
    url('./Lato-Italic.woff') format('woff'),
    url('./Lato-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'helvetica';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('./Lato-Bold.ttf');
  src: url('./Lato-Bold.woff2') format('woff2'),
    url('./Lato-Bold.woff') format('woff'),
    url('./Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'helvetica';
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src: url('./Lato-BoldItalic.ttf');
  src: url('./Lato-BoldItalic.woff2') format('woff2'),
    url('./Lato-BoldItalic.woff') format('woff'),
    url('./Lato-BoldItalic.ttf') format('truetype');
}

/* Lucida Console substitute - Old Standard TT */

@font-face {
font-family: 'lucida console';
font-weight: normal;
font-style: normal;
font-display: swap;
src: url('./OldStandardTT-Regular.ttf');
src: url('./OldStandardTT-Regular.woff2') format('woff2'),
  url('./OldStandardTT-Regular.woff') format('woff'),
  url('./OldStandardTT-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'lucida console';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('./OldStandardTT-Italic.ttf');
  src: url('./OldStandardTT-Italic.woff2') format('woff2'),
    url('./OldStandardTT-Italic.woff') format('woff'),
    url('./OldStandardTT-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'lucida console';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('./OldStandardTT-Bold.ttf');
  src: url('./OldStandardTT-Bold.woff2') format('woff2'),
    url('./OldStandardTT-Bold.woff') format('woff'),
    url('./OldStandardTT-Bold.ttf') format('truetype');
}

/* Monospace substitute - Fira Mono */

@font-face {
font-family: 'monospace';
font-weight: normal;
font-style: normal;
font-display: swap;
src: url('./FiraMono-Regular.ttf');
src: url('./FiraMono-Regular.woff2') format('woff2'),
  url('./FiraMono-Regular.woff') format('woff'),
  url('./FiraMono-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'monospace';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('./FiraMono-Bold.ttf');
  src: url('./FiraMono-Bold.woff2') format('woff2'),
    url('./FiraMono-Bold.woff') format('woff'),
    url('./FiraMono-Bold.ttf') format('truetype');
}  

/* Open Sans substitute - Nunito Sans */

@font-face {
font-family: 'open sans';
font-weight: normal;
font-style: normal;
font-display: swap;
src: url('./OpenSans-Regular.ttf');
src: url('./OpenSans-Regular.woff2') format('woff2'),
  url('./OpenSans-Regular.woff') format('woff'),
  url('./OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'open sans';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('./OpenSans-Italic.ttf');
  src: url('./OpenSans-Italic.woff2') format('woff2'),
    url('./OpenSans-Italic.woff') format('woff'),
    url('./OpenSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'open sans';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('./OpenSans-Bold.ttf');
  src: url('./OpenSans-Bold.woff2') format('woff2'),
    url('./OpenSans-Bold.woff') format('woff'),
    url('./OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'open sans';
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src: url('./OpenSans-BoldItalic.ttf');
  src: url('./OpenSans-BoldItalic.woff2') format('woff2'),
    url('./OpenSans-BoldItalic.woff') format('woff'),
    url('./OpenSans-BoldItalic.ttf') format('truetype');
}

/* Rubik */

@font-face {
font-family: 'rubik';
font-weight: normal;
font-style: normal;
font-display: swap;
src: url('./Rubik-Regular.ttf');
src: url('./Rubik-Regular.woff2') format('woff2'),
  url('./Rubik-Regular.woff') format('woff'),
  url('./Rubik-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'rubik';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('./Rubik-Italic.ttf');
  src: url('./Rubik-Italic.woff2') format('woff2'),
    url('./Rubik-Italic.woff') format('woff'),
    url('./Rubik-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'rubik';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('./Rubik-Bold.ttf');
  src: url('./Rubik-Bold.woff2') format('woff2'),
    url('./Rubik-Bold.woff') format('woff'),
    url('./Rubik-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'rubik';
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src: url('./Rubik-BoldItalic.ttf');
  src: url('./Rubik-BoldItalic.woff2') format('woff2'),
    url('./Rubik-BoldItalic.woff') format('woff'),
    url('./Rubik-BoldItalic.ttf') format('truetype');
}

/* Sans-Serif substitute - Nunito Sans */

@font-face {
font-family: 'sans-serif';
font-weight: normal;
font-style: normal;
font-display: swap;
src: url('./NunitoSans-Regular.ttf');
src: url('./NunitoSans-Regular.woff2') format('woff2'),
  url('./NunitoSans-Regular.woff') format('woff'),
  url('./NunitoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'sans-serif';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('./NunitoSans-Italic.ttf');
  src: url('./NunitoSans-Italic.woff2') format('woff2'),
    url('./NunitoSans-Italic.woff') format('woff'),
    url('./NunitoSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'sans-serif';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('./NunitoSans-Bold.ttf');
  src: url('./NunitoSans-Bold.woff2') format('woff2'),
    url('./NunitoSans-Bold.woff') format('woff'),
    url('./NunitoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'sans-serif';
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src: url('./NunitoSans-BoldItalic.ttf');
  src: url('./NunitoSans-BoldItalic.woff2') format('woff2'),
    url('./NunitoSans-BoldItalic.woff') format('woff'),
    url('./NunitoSans-BoldItalic.ttf') format('truetype');
}

/* Serif substitute - PlayfairDisplay Display */

@font-face {
font-family: 'serif';
font-weight: normal;
font-style: normal;
font-display: swap;
src: url('./PlayfairDisplay-Regular.ttf');
src: url('./PlayfairDisplay-Regular.woff2') format('woff2'),
  url('./PlayfairDisplay-Regular.woff') format('woff'),
  url('./PlayfairDisplay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'serif';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('./PlayfairDisplay-Italic.ttf');
  src: url('./PlayfairDisplay-Italic.woff2') format('woff2'),
    url('./PlayfairDisplay-Italic.woff') format('woff'),
    url('./PlayfairDisplay-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'serif';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('./PlayfairDisplay-Bold.ttf');
  src: url('./PlayfairDisplay-Bold.woff2') format('woff2'),
    url('./PlayfairDisplay-Bold.woff') format('woff'),
    url('./PlayfairDisplay-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'serif';
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src: url('./PlayfairDisplay-BoldItalic.ttf');
  src: url('./PlayfairDisplay-BoldItalic.woff2') format('woff2'),
    url('./PlayfairDisplay-BoldItalic.woff') format('woff'),
    url('./PlayfairDisplay-BoldItalic.ttf') format('truetype');
}

/* Tahoma substitute - Signika */

@font-face {
font-family: 'tahoma';
font-weight: normal;
font-style: normal;
font-display: swap;
src: url('./Signika-Regular.ttf');
src: url('./Signika-Regular.woff2') format('woff2'),
  url('./Signika-Regular.woff') format('woff'),
  url('./Signika-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'tahoma';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('./Signika-Bold.ttf');
  src: url('./Signika-Bold.woff2') format('woff2'),
    url('./Signika-Bold.woff') format('woff'),
    url('./Signika-Bold.ttf') format('truetype');
}

/* Times New Roman substitute - Tinos */

@font-face {
font-family: 'times new roman';
font-weight: normal;
font-style: normal;
font-display: swap;
src: url('./Tinos-Regular.ttf');
src: url('./Tinos-Regular.woff2') format('woff2'),
  url('./Tinos-Regular.woff') format('woff'),
  url('./Tinos-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'times new roman';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('./Tinos-Italic.ttf');
  src: url('./Tinos-Italic.woff2') format('woff2'),
    url('./Tinos-Italic.woff') format('woff'),
    url('./Tinos-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'times new roman';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('./Tinos-Bold.ttf');
  src: url('./Tinos-Bold.woff2') format('woff2'),
    url('./Tinos-Bold.woff') format('woff'),
    url('./Tinos-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'times new roman';
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src: url('./Tinos-BoldItalic.ttf');
  src: url('./Tinos-BoldItalic.woff2') format('woff2'),
    url('./Tinos-BoldItalic.woff') format('woff'),
    url('./Tinos-BoldItalic.ttf') format('truetype');
}

/* Verdana substitute - Jost */

@font-face {
font-family: 'verdana';
font-weight: normal;
font-style: normal;
font-display: swap;
src: url('./Jost-Regular.ttf');
src: url('./Jost-Regular.woff2') format('woff2'),
  url('./Jost-Regular.woff') format('woff'),
  url('./Jost-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'verdana';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('./Jost-Italic.ttf');
  src: url('./Jost-Italic.woff2') format('woff2'),
    url('./Jost-Italic.woff') format('woff'),
    url('./Jost-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'verdana';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('./Jost-Bold.ttf');
  src: url('./Jost-Bold.woff2') format('woff2'),
    url('./Jost-Bold.woff') format('woff'),
    url('./Jost-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'verdana';
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src: url('./Jost-BoldItalic.ttf');
  src: url('./Jost-BoldItalic.woff2') format('woff2'),
    url('./Jost-BoldItalic.woff') format('woff'),
    url('./Jost-BoldItalic.ttf') format('truetype');
}